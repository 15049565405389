<template>
  <v-card class="px-4 pb-4 pt-5" width="400">
    <v-img :src="logoImg" contain alt="DroneShield logo with name" />
    <v-card-text>
      <v-form v-model="valid" @submit.prevent="">
        <v-layout row>
          <v-flex xs12>
            <v-container text-xs-center>
              Enter your email address and we will send you an email with
              instructions to reset your password.
            </v-container>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              data-testid="email-field"
              ref="emailField"
              style="max-width: 400px"
              color="primary"
              name="email"
              label="Email"
              id="email"
              type="email"
              clearable
              v-model="email"
              :rules="rules.emailRules"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row v-if="error">
          <v-divider class="mt-5"></v-divider>
          <v-flex xs12>
            <v-alert
              :value="error"
              outline
              color="primary"
              data-testid="error-message"
            >
              {{ error }}
            </v-alert>
          </v-flex>
        </v-layout>
        <v-layout row class="mt-4">
          <v-flex xs12 class="text-xs-center">
            <v-btn
              data-testid="reset-button"
              color="primary"
              outline
              @click="onClickReset"
              :disabled="!valid"
            >
              Reset password
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider class="mt-5"></v-divider>
    <v-card-actions>
      <v-btn flat color="grey" @click="onClickLogin">
        Return to Log In Page
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { FormMixin } from '@/components/Mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ForgotPasswordCard',
  mixins: [FormMixin],
  props: {
    error: {
      type: [String, Object],
      default: null
    },
    status: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: false,
    emailValue: ''
  }),
  computed: {
    ...mapGetters('system', ['assetsPath']),
    email: {
      get() {
        return this.emailValue
      },
      set(val) {
        this.emailValue = val
        this.CLEAR_ERROR()
      }
    },
    logoImg() {
      return require(`@/assets/${this.assetsPath}logo.svg`)
    }
  },
  methods: {
    ...mapActions('auth', ['CLEAR_ERROR']),
    onClickReset() {
      if (this.valid) {
        this.$emit('clickReset', {
          reset: true,
          forgotten: true,
          email: this.email
        })
      }
    },
    onClickLogin() {
      this.$emit('clickLogin')
    }
  },
  mounted() {
    this.$refs.emailField.focus()
  }
}
</script>
